<template>
    <TransitionRoot as="template" :show="showing" >
        <Dialog as="div" class="fixed inset-0 z-[1999] overflow-hidden xl:hidden" @close="$emit('close-nav')" >
          <div class="absolute inset-0 overflow-hidden">
              <TransitionChild
              enter="duration-500 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-300 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="bg-black/70 fixed inset-0" />
            </TransitionChild>

            <div class="lg:w-2/5 sm:w-3/5 fixed inset-y-0 right-0 flex w-4/5">

              <TransitionChild
                as="template"
                enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full"
                enter-to="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                leave-to="translate-x-full">
                <div class="w-screen overflow-y-auto">
                  <div class="pt-[100px] md:pt-[150px] overflow-y-auto bg-brand-blue h-full px-12 pb-4">

                     <button @click="$emit('close-nav')" class="absolute text-white top-[30px] right-[25px]">
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="1" viewBox="5.5 5.5 13 13">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </button>

                      <ul class="mb-0 space-y-3 text-xl">
                         <li  v-for="entry in entries" :key="entry.id">
                          <a :href="entry.url" class="block text-white">{{ entry.title }}</a>
                        </li>
                      </ul>


                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

export default {
    props: {
        showNav: {
            required: true,
            type: Boolean,
        },
        entries: {
            required: true,
            type: Array,
        }
     },
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    watch: {
      // work around for the fact that the animation won't fire on the first render when the value is passed as prop.
      showNav(val) {
        if (val) {
          this.$nextTick(()=>this.showing = true);
          return;
        }
        this.showing = false;
      }
    },
    data() {
        return {
            showing: false,
        };
    },
    methods: {
        toggleNav() {
            this.showing = !this.showing;
        },
    },
};
</script>
