<template>
    <div class="images-carousel">
        <swiper :modules="modules" :pagination="{ clickable: true }">
            <swiper-slide v-for="(item, key) in items" :key="key">
                <figure>
                    <img :src="item.src" :alt="item.title" />
                </figure>
            </swiper-slide>


        </swiper>
    </div>
</template>

<!-- <script>
import { Carousel, Slide, Pagination, Navigation } from "vue-carousel";

export default {
    props: {
        items: {
            required: true,
            type: Array,
        },
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },

      <template #addons>
                <navigation />
                <pagination />
            </template>
};

:wrapAround="true"

</script> -->


<script setup>
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper/modules';
const modules = [ Autoplay, Pagination];

// don't autoplay on for 3s
const pauseAutoplay = (swiper) => {
  swiper.autoplay.stop();
  setTimeout(() => {
    swiper.autoplay.start();
  }, 3000);
};

const props = defineProps({
  items: {
    type: Array,
    required: false,
  },

});
</script>

