import "../css/app.css";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: process.env.NODE_ENV,
  })

  if (typeof userId !== 'undefined' && userId &&  typeof userName !== 'undefined' && userName) {
    Bugsnag.setUser(userId, '', userName)
  }
const bugsnagVue = Bugsnag.getPlugin('vue')

import { createApp, ref } from 'vue';
import MobileNavigation from "./Components/Navigation/MobileNavigation.vue"
import ImagesCarousel from "./Components/Carousels/ImagesCarousel.vue"

createApp({
    compilerOptions: {
        whitespace: 'condense',
        inheritAttrs: true,
      },
    components: {
        MobileNavigation,
        ImagesCarousel,
    },
    setup() {
        const navOpen = ref(false);

        return {
            navOpen,
        };
    },
    mounted() {

    }

})
.use(bugsnagVue)
.mount('#app');
